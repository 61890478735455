<template>
  <div class="d-flex">
    <v-btn v-if="!hidePrev" color="primary" text @click="$emit('prev')">
      <v-icon left>fas fa-arrow-left</v-icon> Previous
    </v-btn>
    <v-spacer></v-spacer>
    <v-btn v-if="!hideNext" color="primary" text @click="$emit('next')">
      Next <v-icon right>fas fa-arrow-right</v-icon>
    </v-btn>
    <v-btn
      v-else
      color="primary"
      :loading="loading"
      :disabled="submitDisabled"
      @click="$emit('submit')"
    >
      <v-icon left>fas fa-save</v-icon> Confirm
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "StepNav",
  props: {
    hidePrev: {
      type: Boolean,
      default: false,
    },
    hideNext: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    submitDisabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
