<template>
  <v-dialog
    v-model="hideShowDialog"
    scrollable
    width="600"
    eager
    persistent
    @keydown.esc="closeHideShow"
  >
    <template #activator="{ on, attrs }">
      <v-tooltip bottom :color="tooltipColor" disabled>
        <template #activator="{ on: tooltipOn, attrs: tooltipAttrs }">
          <div v-bind="tooltipAttrs" v-on="tooltipOn">
            <v-btn
              v-bind="attrs"
              color="primary"
              :disabled="cameras.length == 0"
              small
              class="mr-1"
              v-on="on"
            >
              <v-icon small>fa-plus</v-icon>
              Add Extraction
            </v-btn>
          </div>
        </template>
        <span>Extraction disabled due to closed port</span>
      </v-tooltip>
    </template>
    <v-card class="overflow-y-auto">
      <v-card-title>Snapshot Extractor</v-card-title>
      <v-divider></v-divider>
      <ValidationObserver v-slot="{ invalid, passes }" ref="snapshotExtractor">
        <v-card-text class="pt-5">
          <ValidationProvider
            v-slot="{ errors }"
            name="Cameras"
            rules="required"
          >
            <v-combobox
              v-model="selectedCameras"
              label="Cameras"
              :error-messages="errors"
              multiple
              outlined
              readonly
              dense
              append-icon=""
            />
          </ValidationProvider>
          <ERow no-gutters>
            <ECol cols="6" sm="6" md="6" class="pr-2">
              <v-menu
                v-model="startDatePicker"
                :close-on-content-click="false"
                max-width="290"
              >
                <template #activator="{ on, attrs }">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="startDate"
                    :rules="{
                      required: true,
                      date: true,
                      duration_limit: ['@endDate', 'startDate', 4],
                    }"
                  >
                    <v-text-field
                      v-model="startDate"
                      clearable
                      outlined
                      placeholder="Start Date"
                      label="Start Date"
                      :error-messages="errors"
                      readonly
                      dense
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="startDate = ''"
                    ></v-text-field>
                  </ValidationProvider>
                </template>
                <v-date-picker
                  v-model="startDate"
                  no-title
                  @change="startDatePicker = false"
                ></v-date-picker>
              </v-menu>
            </ECol>
            <ECol cols="6" sm="6" md="6">
              <v-menu
                v-model="endDatePicker"
                :close-on-content-click="false"
                max-width="290"
              >
                <template #activator="{ on, attrs }">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="endDate"
                    :rules="{
                      required: true,
                      date: true,
                      duration_limit: ['@startDate', 'endDate', 4],
                    }"
                  >
                    <v-text-field
                      v-model="endDate"
                      outlined
                      clearable
                      dense
                      :error-messages="errors"
                      placeholder="End Date"
                      label="End Date"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="endDate = ''"
                    ></v-text-field>
                  </ValidationProvider>
                </template>
                <v-date-picker
                  v-model="endDate"
                  no-title
                  @change="endDatePicker = false"
                ></v-date-picker>
              </v-menu>
            </ECol>
            <ECol cols="6" sm="6" md="6" class="pr-2">
              <v-select
                v-model="interval"
                :items="intervals"
                label="Interval"
                dense
                outlined
              ></v-select>
            </ECol>
            <ECol cols="6" sm="6" md="6">
              <v-select
                v-model="extractionType"
                :items="extractionTypes"
                item-text="name"
                dense
                label="Extraction"
                outlined
                @change="() => $refs.snapshotExtractor.validate()"
              ></v-select>
            </ECol>
            <ECol
              v-if="isLocalSnapshotExtraction"
              cols="6"
              sm="6"
              md="6"
              class="pr-2"
            >
              <v-select
                v-model="injectToCr"
                :items="yesNo"
                label="Sync to Cloud Recordings"
                dense
                outlined
                @change="() => $refs.snapshotExtractor.validate()"
              ></v-select>
            </ECol>
            <ECol v-if="isLocalSnapshotExtraction" cols="6" sm="6" md="6">
              <v-select
                v-model="jpegsToDropbox"
                :items="yesNo"
                dense
                label="Jpegs To Dropbox"
                outlined
                @change="() => $refs.snapshotExtractor.validate()"
              ></v-select>
            </ECol>
          </ERow>
          <v-select
            v-model="scheduleType"
            :items="scheduleTypes"
            label="Schedule"
            dense
            outlined
            @change="handleChange"
          ></v-select>
          <Schedule
            v-if="showScheduler"
            v-model="schedule"
            :schedule-type="scheduleType"
          />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeHideShow">Close</v-btn>
          <v-btn
            color="primary"
            text
            :disabled="invalid"
            @click="passes(addExtraction)"
            >Add</v-btn
          >
        </v-card-actions>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>

<script>
import schedules from "@evercam/shared/constants/schedules"
import {
  SnapshotExtractionIntervals,
  SnapshotExtractionTypes,
} from "@/components/constants.js"
import Schedule from "@/components/Schedule"
import { extend } from "vee-validate"
import { SnapshotExtractionType } from "@evercam/shared/types"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { ScheduleType } from "@evercam/shared/types"
import { mapStores } from "pinia"
import { useAccountStore } from "@/stores/account"

export default {
  components: {
    Schedule,
  },
  props: {
    cameras: {
      type: Array,
      required: true,
    },
  },
  data: () => {
    return {
      loading: true,
      extractionDisabled: false,
      tooltipColor: "#262526",
      schedule: schedules.WORKING_HOURS,
      defaultSchedule: schedules.WORKING_HOURS,
      yesNo: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
      startDatePicker: false,
      startDate: "",
      endDatePicker: false,
      endDate: "",
      hideShowDialog: false,
      interval: 10,
      intervals: SnapshotExtractionIntervals,
      extractionTypes: SnapshotExtractionTypes,
      extractionType: SnapshotExtractionType.Cloud,
      scheduleTypes: [
        {
          text: "M-F 08:00-18:00",
          value: ScheduleType.WorkingHours,
        },
        {
          text: "Continuous",
          value: ScheduleType.Continuous,
        },
        {
          text: "Custom",
          value: ScheduleType.OnSchedule,
        },
      ],
      scheduleType: ScheduleType.WorkingHours,
      jpegsToDropbox: false,
      injectToCr: false,
    }
  },
  computed: {
    ...mapStores(useAccountStore),
    selectedCameras() {
      return this.cameras.map((camera) => camera.name)
    },
    showScheduler() {
      return this.scheduleType === ScheduleType.OnSchedule
    },
    addButton() {
      return this.startDate === "" || this.endDate === ""
    },
    isLocalSnapshotExtraction() {
      return this.extractionType === SnapshotExtractionType.Local
    },
  },
  watch: {
    extractionType: {
      handler(value) {
        if (value === SnapshotExtractionType.Cloud) {
          this.injectToCr = false
          this.jpegsToDropbox = false
        }
      },
      immediate: true,
    },
    cameras: "checkPortStatus",
  },
  created() {
    this.checkPortStatus()
  },
  mounted() {
    extend("duration_limit", {
      params: ["target", "fieldName", "days"],
      validate: (value, { target, fieldName, days }) => {
        let startDate, endDate
        if (fieldName === "endDate") {
          endDate = this.$moment(value)
          startDate = this.$moment(target)
        } else {
          endDate = this.$moment(target)
          startDate = this.$moment(value)
        }

        const isDropboxExtraction =
          this.extractionType === SnapshotExtractionType.Cloud

        return (
          endDate.isSameOrAfter(endDate) &&
          (isDropboxExtraction ||
            endDate.diff(startDate, "days") <= Number.parseInt(days))
        )
      },
      message: (field, { days }) => {
        return `Both start and end dates must be defined and the max duration between dates is ${days} days`
      },
    })
  },
  methods: {
    async checkPortStatus() {
      let port
      let host = this.cameras[0]?.cameraHost
      if (
        this.cameras[0]?.nvrModel === "hikvision" ||
        this.cameras[0]?.nvrModel === "dahua"
      ) {
        port = this.cameras[0]?.nvrRtspPort
      } else if (this.cameras[0]?.nvrModel === "nxwitness") {
        port = this.cameras[0]?.nvrHttpPort
      }
      try {
        if (!port || !host) {
          this.extractionDisabled = false

          return
        }
        const { open } = await EvercamApi.cameras.portCheck({
          params: {
            address: host,
            port: port,
          },
        })
        this.extractionDisabled = open
      } catch (error) {
        this.$notifications.error({
          text: "Could not check port status!",
          error,
        })
      }
    },
    handleChange() {
      if (this.scheduleType === ScheduleType.Continuous) {
        this.schedule = schedules.FULL
      } else if (this.scheduleType === ScheduleType.WorkingHours) {
        this.schedule = schedules.WORKING_HOURS
      } else if (this.scheduleType === ScheduleType.OnSchedule) {
        this.schedule = schedules.EMPTY
      }
    },
    clearForm() {
      this.schedule = this.defaultSchedule
      this.scheduleType = ScheduleType.WorkingHours
      this.startDate = ""
      this.endDate = ""
      this.interval = 10
      this.injectToCr = false
      this.jpegsToDropbox = false
      this.extractionType = SnapshotExtractionType.Cloud
      this.$refs.snapshotExtractor.reset()
    },
    stopLoading(status) {
      this.loading = !status
    },
    parseSchedule() {
      try {
        return JSON.parse(this.schedule)
      } catch (_) {
        return this.schedule
      }
    },
    async addExtraction() {
      let encounteredError = false
      for (const camera of this.cameras) {
        try {
          await AdminApi.extractions.addExtraction(camera.exid, {
            cameraId: camera.id,
            fromDate: this.$moment(`${this.startDate} 00:00:00`).format(
              "YYYY-MM-DDTHH:mm:ssZ"
            ),
            toDate: this.$moment(`${this.endDate} 23:59:59`).format(
              "YYYY-MM-DDTHH:mm:ssZ"
            ),
            schedule: this.parseSchedule(),
            interval: this.interval,
            jpegsToDropbox: this.jpegsToDropbox,
            injectToCr: this.injectToCr,
            requestor: this.accountStore.email,
            type: this.extractionType,
            ignoreCasingFromParams: ["schedule"],
          })
        } catch (error) {
          encounteredError = true
          this.$notifications.error({
            text: "Snaphot Extaction failed!",
            error,
          })
        }
      }
      this.$emit("extraction-added")
      if (!encounteredError) {
        this.$notifications.success("Snaphot Extaction(s) have been started.")
      }
      this.clearForm()
      this.hideShowDialog = false
    },
    closeHideShow() {
      this.clearForm()
      this.hideShowDialog = false
    },
  },
}
</script>
