<template>
  <v-dialog v-model="dialog" width="auto">
    <template #activator="{ on, attrs }">
      <v-alert
        prominent
        text
        dense
        color="red"
        type="warning"
        class="py-1 w-100"
        icon="fas fa-exclamation-triangle"
      >
        <ERow align="center">
          <ECol class="py-0">
            <span class="subtitle-1"
              >This will permanently delete the camera, proceed with
              caution.</span
            >
          </ECol>
          <ECol class="shrink pl-0">
            <v-btn color="error" small v-bind="attrs" v-on="on">delete</v-btn>
          </ECol>
        </ERow>
      </v-alert>
    </template>

    <v-card>
      <v-card-title primary-title> Are you absolutely sure?</v-card-title>
      <v-divider />
      <ValidationObserver ref="form" v-slot="{ invalid, passes }">
        <v-card-text>
          <ERow>
            <ECol cols="12" class="mt-n6">
              <ValidationProvider
                v-slot="{ errors }"
                name="Camera name"
                :rules="{
                  required: true,
                  equal_to: `${cameraDialogStore.camera.name}`,
                }"
              >
                <v-text-field
                  v-model="verify"
                  type="text"
                  autocomplete="new-name"
                  :error-messages="errors"
                  label="Enter the camera'name to delete"
                >
                  <template #label>
                    <div class="caption label-color">Camera name</div>
                  </template>
                </v-text-field>
              </ValidationProvider>
            </ECol>
          </ERow>
          <ERow>
            <ECol cols="12" class="my-n6">
              <ValidationProvider
                v-slot="{ errors }"
                name="This"
                rules="required"
              >
                <v-text-field
                  v-model="password"
                  type="password"
                  autocomplete="new-password"
                  :error-messages="errors"
                  label="Enter your current password"
                >
                  <template #label>
                    <div class="caption label-color">Password</div>
                  </template>
                </v-text-field>
              </ValidationProvider>
            </ECol>
          </ERow>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            :disabled="invalid"
            @click="passes(confirm)"
            >Yes, delete this camera</v-btn
          >
          <v-btn color="primary" text @click="close">Cancel</v-btn>
        </v-card-actions>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapStores } from "pinia"
import { useCameraDialogStore } from "@/stores/cameraDialog"

export default {
  name: "DeleteCameraDialog",
  data() {
    return {
      password: "",
      verify: "",
      dialog: false,
    }
  },
  computed: {
    ...mapStores(useCameraDialogStore),
  },
  methods: {
    close() {
      this.$refs.form?.reset()
      this.dialog = false
    },
    confirm() {
      const payload = {
        password: this.password,
        verify: this.verify,
      }
      this.cameraDialogStore.deleteCamera({ payload })
      this.close()
    },
  },
}
</script>
