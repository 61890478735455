<template>
  <ValidationProvider v-slot="{ errors }" :name="name" :rules="rules">
    <v-text-field
      :value="value"
      class="caption"
      :error-messages="errors"
      :maxlength="5"
      persistent-hint
      :label="label"
      :placeholder="placeholder"
      dense
      outlined
      @input="(v) => $emit('input', v)"
      @keypress="allowKeypress($event, { number: true })"
    >
      <template v-if="showPortValidation" #append>
        <span
          v-if="!portValidationProgress"
          class="mt-1 min-width80 text-right"
        >
          <span v-if="portValidationValue" class="green--text">Open</span>
          <span v-else class="red--text">Closed</span>
        </span>
        <v-progress-circular v-else size="22" indeterminate color="grey" />
      </template>
      <template v-if="host && value" #append-outer>
        <v-tooltip bottom content-class="d-inline-block">
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              color="primary"
              x-small
              v-bind="attrs"
              target="_blank"
              class="ml-n1 pb-05"
              :href="`http://${host}:${value}`"
              v-on="on"
              @click.stop="() => {}"
            >
              <v-icon small> fa-external-link-alt </v-icon>
            </v-btn>
          </template>
          <span>Open In A New Window</span>
        </v-tooltip>
      </template>
    </v-text-field>
  </ValidationProvider>
</template>

<script>
export default {
  props: {
    value: {
      type: [Number, String],
      default: null,
    },
    host: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    showPortValidation: {
      type: Boolean,
      default: false,
    },
    portValidationValue: {
      type: Boolean,
      default: false,
    },
    portValidationProgress: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: null,
    },
    rules: {
      type: [String, Object],
      default: () => "",
    },
  },
}
</script>
